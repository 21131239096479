import React, { useState } from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  useToast,
  Box,
  Select,
  Text,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Checkbox,
  Badge,
} from "@chakra-ui/react";
import ConsentModal from "./consentmodal";

const SignUp = (props) => {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [secondPassword, setSecondPassword] = useState();
  const [email, setEmail] = useState();
  const [age, setAge] = useState();
  const [gender, setGender] = useState();
  const [hand, setHand] = useState();
  const toast = useToast({ position: "top" });
  const [seeCustom, setSeeCustom] = useState(false);
  const backend = process.env.REACT_APP_IP;
  const keybind = Math.random();
  const [isChecked, setIsChecked] = useState(false);
  const { onOpen, isOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const {
    onOpen: dataModalOnOpen,
    isOpen: dataModalIsOpen,
    onClose: dataModalOnClose,
  } = useDisclosure();

  const handleCheckboxChange = (e) => {
    e.preventDefault();
    setIsChecked(e.target.checked);
  };

  const handleModalAgree = () => {
    setIsChecked(true);
    onClose();
  };

  const transition1 = () => {
    props.user(username);
    sessionStorage.setItem("user", username);
    sessionStorage.setItem("keybind", keybind);
    props.practice(true);
    props.setInTrial(true);
    props.seeNav(false);
    props.hideself(false);
    props.transition1(true);
  };

  const transition2 = () => {
    props.hideself(false);
    props.transition2(true);
  };

  const signUp = async () => {
    if (!isChecked) {
      toast({
        title: "Consent Form required",
        description: "Please read and accept the Consent Form.",
        status: "error",
      });
    } else if (password !== secondPassword) {
      toast({
        title: "Passwords do not match",
        description: "Please check that your passwords match",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } else if (username && hand && gender) {
      // console.log("account creation: ", keybind);
      const body = JSON.stringify({
        username: username,
        password: secondPassword,
        email: email,
        age: age,
        gender: gender,
        hand: hand,
        keybind: keybind,
      });
      const response = await fetch(backend + "signup", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: body,
      });
      const result = await response.json();
      console.log(result);
      if (result === "error creating user") {
        toast({
          title: "Error",
          description:
            "There has been an error creating your account. Please try again",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      } else if (result === "user exists") {
        toast({
          title: "User already exists",
          description: "Please select another username",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      } else if (result === "success") {
        toast({
          title: "Account created",
          description: "Your account has been created",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        transition1();
      }
    } else {
      toast({
        title: "Required fields",
        description: "Please enter all required fields",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const checkSame = (secondPass) => {
    if (secondPass === password) {
      setSecondPassword(secondPass);
    }
  };

  const updateForm = (e) => {
    if (e === "Custom") {
      setSeeCustom(true);
    } else {
      setSeeCustom(false);
      setGender(e);
    }
  };

  return (
    <Box className={`${props.shown ? "" : "active"} show`}>
      <Box ml="auto" mr="auto" w="40%" textAlign={"center"}>
        <Box fontSize={"2xl"}>
          <Text
            ml="auto"
            mr="auto"
            w={"60%"}
            fontWeight={"bold"}
            borderRadius={10}
            bgGradient="linear(to-l, #9146db, #0081d4)"
          >
            Start playing to win cash prizes!
          </Text>
          <Text
            id="signup=prize"
            ml="auto"
            mr="auto"
            w={"80%"}
            mt={4}
            fontWeight={"bold"}
            borderRadius={10}
            fontSize={"xl"}
            // bgGradient="linear(to-l, #9146db, #30ACFB)"
            // bgClip={"text"}
            // bgClip="text"
            // bgGradient="linear(to-l, #9146db, #30ACFB)"
          >
            First place this Wednesday receives{" "}
            <Badge
              bgGradient="linear(to-l, #9146db, #0081d4)"
              colorScheme="default"
              borderRadius="md"
              fontSize="lg"
              ml={1}
            >
              $10
            </Badge>
          </Text>
        </Box>
      </Box>
      <Box className="signup-container">
        <Box className="signup-box">
          <Text
            id="title"
            textAlign={"left"}
            fontWeight={"bold"}
            bgGradient="linear(to-l, #9146db, #30ACFB)"
            bgClip="text"
            mb={3}
          >
            Sign up to get started
          </Text>
          <Box fontSize={"small"} textAlign={"left"} mt={5} mb={5}>
            <label id="required-fields">
              <span style={{ color: "red" }}>*</span> indicates required fields
            </label>
            <Box fontSize={"small"} textAlign={"left"}>
              <label id="required-fields">
                Email is only required to receive prizes.
              </label>
            </Box>
          </Box>

          <form
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                signUp();
              }
            }}
          >
            {/* Labels and inputs for form data */}
            <Box>
              <Box>
                <label className="label">
                  Username<span style={{ color: "red" }}>*</span>
                </label>
                <Input
                  mb={5}
                  className="input"
                  // bg = "white"
                  type="text"
                  autoComplete="username"
                  onChange={(e) => setUsername(e.target.value)}
                />

                <label className="label">
                  Password<span style={{ color: "red" }}>*</span>
                </label>
                <Input
                  mb={5}
                  className="input"
                  // bg = "white"
                  type="password"
                  autoComplete="new-password"
                  onChange={(e) => setPassword(e.target.value)}
                />

                <label className="label">
                  Confirm Password<span style={{ color: "red" }}>*</span>
                </label>
                <Input
                  mb={5}
                  className="input"
                  // bg = "white"
                  type="password"
                  autoComplete="new-password"
                  onChange={(e) => checkSame(e.target.value)}
                />
                <Button
                  mt={5}
                  variant={"unstyled"}
                  color="rgb(109, 153, 211)"
                  className="info-btn"
                  type="button"
                  onClick={dataModalOnOpen}
                >
                  Why do we collect this information?
                </Button>
                <Box>
                  <label className="label">Email</label>
                  {/* <Text 
                // className="email-note" 
                textAlign={'left'}
                color='black'
                fontSize={'sm'}
              >
                (Required to receive prizes)
              </Text> */}
                </Box>
                <Input
                  mb={5}
                  className="input"
                  // bg = "white"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Box>

              <Box>
                <label className="label">Age</label>
                <Box mb={5}>
                  <NumberInput
                    min={18}
                    max={120}
                    onChange={(val) => setAge(val)}
                    // bg = "white"
                    borderRadius={"md"}
                    precision={0}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Box>

                <label className="label">
                  Gender<span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  mb={5}
                  placeholder="Select..."
                  onChange={(e) => updateForm(e.target.value)}
                  // // bg = "white"
                >
                  <option>Man</option>
                  <option>Woman</option>
                  <option>Prefer Not to Say</option>
                </Select>
                {seeCustom && (
                  <>
                    <div className="label">
                      <Input
                        className="input"
                        type="text"
                        // bg = "white"
                        mt={2}
                        onChange={(e) => setGender(e.target.value)}
                      />
                    </div>
                  </>
                )}
                <label className="label">
                  Dominant Hand<span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  mb={5}
                  placeholder="Select..."
                  onChange={(e) => setHand(e.target.value)}
                  // bg = "white"
                >
                  <option>Right</option>
                  <option>Left</option>
                  <option>Ambidextrous</option>
                </Select>
              </Box>
            </Box>
            <Checkbox isChecked={isChecked} onChange={handleCheckboxChange}>
              I have read and accept the{" "}
              <Button
                variant="link"
                color="rgb(109, 153, 211)"
                onClick={onOpen}
              >
                Consent Form. <span style={{ color: "red" }}>*</span>
              </Button>
            </Checkbox>

            <Button
              mt={5}
              mb={5}
              width="100%"
              type="button"
              colorScheme="blue"
              onClick={() => {
                signUp();
              }}
              // className="submit-btn"
              // type="button"
            >
              Sign up
            </Button>
            <Box display={"flex"} justifyContent={"center"} mb={5}>
              <Text fontSize={"medium"} p={"auto"}>
                Already have an account?
              </Text>
              <Button
                variant={"unstyled"}
                // className="rtrn-btn"
                mb={"3vh"}
                ml={1}
                color="rgb(109, 153, 211)"
                textDecoration={"underline"}
                height={"0"}
                onClick={() => {
                  transition2();
                }}
              >
                Sign in
              </Button>
            </Box>

            <ConsentModal
              isOpen={isOpen}
              onClose={onClose}
              onAgree={handleModalAgree}
            />

            <Modal
              isOpen={dataModalIsOpen}
              onClose={dataModalOnClose}
              isCentered
            >
              <ModalOverlay />
              <ModalContent bgColor="#1a202c">
                <ModalHeader color="#30ACFB">
                  How will my data be used?{" "}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  We are using the data from this experiment to train a set of
                  AI models that are more aligned with biology than anything the
                  field has seen. Beyond creating newer and more computationally
                  efficient AI, this data can be used to robustly assess the
                  alignment between existing computer vision models and humans.
                  <br />
                  <br />
                  You are in control of your personal information. You always
                  have the right to delete your account, permanently removing
                  your email and personally identifiable data from all of our
                  databases.
                </ModalBody>

                <ModalFooter>
                  <Button colorScheme="blue" mr={3} onClick={dataModalOnClose}>
                    Close
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default SignUp;
