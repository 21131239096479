import { Box, Text } from "@chakra-ui/react";
// import { firstPrize, secondPrize, thirdPrize } from "../pages/Home";

const prizes = ["0"];
const PRIZE_MULTIPLIER = 1; // $5 for each point interval, i.e. 300k

const PrizeBadge = ({ amount, prizeKey }) => {
  //   let total = amount * PRIZE_MULTIPLIER;
  //   if (prizeKey !== null && prizeKey !== undefined && prizeKey < 3) {
  //     console.log(prizeKey);
  //     const prize = prizes[prizeKey];
  //     console.log(prize);
  //     const parsePrize = parseFloat(prize.replace("$", ""));
  //     total += parsePrize;
  //   }
  let total = prizes[prizeKey];

  return (
    <Box
      width={{ base: "8", lg: "12" }}
      //   height="12"
      height={{ base: "8", lg: "12" }}
      borderRadius="50%"
      bgGradient="linear(to-l, #9146db, #30ACFB)"
      //   marginBottom="3"
      //   marginRight="6"
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <Text
        color="gray.100"
        fontWeight="bold"
        fontSize={{ base: "sm", lg: "lg" }}
      >
        ${prizes[prizeKey]}
      </Text>
    </Box>
  );
};

export default PrizeBadge;
