import React, { useState, useEffect } from "react";
import {
  useToast,
  Text,
  Box,
  Input,
  Button,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import CountdownTimer from "./countdowntimer";

const SignIn = (props) => {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const toast = useToast({ position: "top" });
  const backend = process.env.REACT_APP_IP;

  const transition1 = () => {
    sessionStorage.setItem("user", username);
    props.user(username);
    props.hideself(false);
    props.seeNav(true);
    props.transition1(true);
  };

  const transition2 = () => {
    props.hideself(false);
    props.transition2(true);
  };

  const checkUser = async () => {
    if (username && password) {
      let body = JSON.stringify({
        username: username,
        password: password,
      });
      const response = await fetch(backend + "signin", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: body,
      });
      const result = await response.json();
      if (result === "user does not exist" || result === "password incorrect") {
        toast({
          title: "Username or password incorrect",
          description:
            "Please check spelling or sign up if you have not already",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      } else if (result) {
        if (result.keybind) {
          window.sessionStorage.setItem("keybind", result.keybind);
          // console.log("signnin/storage: ", result.keybind);
          toast({
            title: "Welcome back",
            description: "You are now logged in.",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
          // localStorage.setItem("user", username)
          transition1();
        } else {
          toast({
            title: "Error",
            description:
              "There has been an error logging in. Please try again later.",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      } else {
        toast({
          title: "Error",
          description:
            "There has been an error logging in. Please try again later.",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <div
      className={`${props.shown ? "" : "active"} show`}
      overflow-y="auto"
      overflow-x="auto"
    >
      <div className="signin-container">
        <Text
          id="title"
          textAlign={"left"}
          fontWeight={"bold"}
          bgGradient="linear(to-l, #9146db, #30ACFB)"
          bgClip="text"
          mb={3}
        >
          Sign in to play
        </Text>
        <Box
          display={"flex"}
          // justifyContent={"center"}
        >
          {/* <Text 
          // color="whiteAlpha.600" 
          mr={1}
          fontS
          >
          
          New user? Sign up
        </Text>{" "} */}
        </Box>

        <form
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              checkUser();
            }
          }}
        >
          <label className="label">Username</label>
          <Input
            mb={5}
            className="input"
            // bg="white"
            type="text"
            autoComplete="username"
            onChange={(e) => setUsername(e.target.value)}
          />

          <label className="label">Password</label>
          <Input
            className="input"
            // bg="white"
            type="password"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
          />

          <Box>
            {/* <button
            onClick={() => {
              checkUser();
            }}
            className="back-btn"
          >
            Back
          </button> */}
            <Button
              ml="auto"
              mr="auto"
              onClick={() => {
                checkUser();
              }}
              // className="submit-btn"
              mt={"3vh"}
              mb={5}
              width="100%"
              type="button"
              colorScheme="blue"
              // color='#30acfb'
              // variant={'outline'}
            >
              Sign in
            </Button>
            <Button
              mb={"3vh"}
              variant={"unstyled"}
              className="rtrn-btn"
              color="rgb(109, 153, 211)"
              textDecoration={"underline"}
              onClick={() => {
                transition2();
              }}
            >
              New user? Sign up here
            </Button>
          </Box>
        </form>
      </div>
      <Box ml="auto" mr="auto" my={6} p={6} w="50%" textAlign={"center"}>
        <Box borderRadius={10} fontSize={"2xl"} p={5} mb={8}>
          <Text
            id="signin-prize"
            fontWeight={"bold"}
            bgGradient="linear(to-l, #9146db, #30ACFB)"
            bgClip={"text"}
            fontSize={"3xl"}
          >
            WIN CASH PRIZES THIS WEDNESDAY AT 9PM ET
          </Text>
          <Text
            ml="auto"
            mr="auto"
            mt={4}
            w={"30%"}
            borderRadius={10}
            bgGradient="linear(to-l, #9146db, #0081d4)"
          >
            First place wins $0! Game Under Development
          </Text>
        </Box>
      </Box>
    </div>
  );
};

export default SignIn;
